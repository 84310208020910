
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { TeamModule } from '@/modules/team/store'
import { VersionModule } from '@/modules/version/store'

import TeamDropdownList from './dropdown.vue'

@Component({
  components: {
    TeamDropdownList
  },
  name: 'TeamSelectionBar'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly loading!: boolean

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscapeId () {
    return (this.$params.landscapeId || this.currentVersion?.landscapeId)!
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get organizationPermission () {
    return this.currentVersionId === 'latest' ? this.organizationModule.organizationPermission(this.currentOrganization) : 'read'
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get organizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get organizationUsersListStatus () {
    return this.organizationModule.organizationUsersListStatus
  }

  get currentTeamId () {
    return this.$queryValue('team')
  }

  get currentTeam () {
    return this.teamModule.teams.find(o => o.id === this.currentTeamId)
  }

  get teamsListStatus () {
    return this.teamModule.teamsListStatus
  }

  get teams () {
    return this.teamModule.teams
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get teamObjects () {
    return Object.values(this.modelModule.objects).filter(o => this.currentTeam?.modelObjectHandleIds.includes(o.handleId))
  }

  goToTeamObjects () {
    this.$router.push({
      name: 'model-objects',
      params: {
        landscapeId: this.currentLandscapeId || ' ',
        versionId: this.currentVersionId
      },
      query: {
        ...this.$routeName === 'model-objects' ? this.$query : undefined,
        domain: this.currentDomainHandleId || undefined,
        filter: this.currentTeamId
      }
    })
  }
}
