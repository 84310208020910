
import { PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { TeamModule } from '@/modules/team/store'

export interface TeamListItem {
  click: () => void
  editable: boolean
  id: string
  name: string
  userCount: number
}

@Component({
  components: {
    TeamNameEdit: () => import('./name-edit.vue'),
    TeamOptionsMenu: () => import('./options-menu.vue')
  },
  name: 'TeamDropdownList'
})
export default class extends Vue {
  teamModule = getModule(TeamModule, this.$store)

  @Prop({ default: false, type: Boolean }) readonly loading?: boolean
  @Prop() readonly permission!: PermissionType
  @Prop({ default: false, type: Boolean }) readonly removable!: boolean

  @Ref() readonly teamMenuRef!: any

  updateId: string | null = null
  hoverMenuItemId: string | null = null
  optionsMenuItemId: string | null = null

  get currentTeamId () {
    return this.$queryValue('team')
  }

  get currentTeam () {
    return this.teamModule.teams.find(o => o.id === this.currentTeamId)
  }

  get items () {
    return this.teamModule.teams
      .map((o): TeamListItem => ({
        click: () => {
          if (o.id === this.updateId) {
            return
          }
          this.$replaceQuery({
            team: o.id
          })
          this.close()
        },
        editable: this.permission !== 'read' && (this.currentTeamId === o.id || this.hoverMenuItemId === o.id),
        id: o.id,
        name: o.name,
        userCount: o.userIds.length
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  close () {
    this.updateId = null
    this.teamMenuRef.isActive = false
  }

  createNewTeam () {
    this.$emit('new-team')
  }
}
